import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"fallback\":[\"sans-serif\"],\"weight\":\"variable\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Dystopian-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Dystopian-Regular.woff2\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Dystopian-Bold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Dystopian-Black.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-dystopian\"}],\"variableName\":\"dystopian\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["OAuthResultToast"] */ "/vercel/path0/src/v2/components/Providers/OAuthResultToast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageViews"] */ "/vercel/path0/src/v2/components/Providers/PostHogPageViews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/v2/components/Providers/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TermsOfUseUpdateDialog"] */ "/vercel/path0/src/v2/components/TermsOfUseUpdateDialog.tsx");
